<template>
  <div>
    <v-container class="mt-12" v-if="record">
      <v-row>
        <v-col cols="4">
          <v-avatar size="100">
            <v-img
              :src="'https://pinn.app/admin/uploads/' + profile.photo"
              aspect-ratio="1.5"
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="8">
          <div class="d-flex justify-space-between align-center">
            <div class="text-h4">{{ profile.name }}</div>
          </div>
          <v-row class="mt-1 hidden-sm-and-down">
            <v-col cols="3">
              <div>
                <span class="font-weight-bold">{{ profile.followers }} </span
                >followers
              </div>
            </v-col>
            <v-col cols="3">
              <div>
                <span class="font-weight-bold">{{ profile.following }} </span
                >following
              </div>
            </v-col>
          </v-row>
          <div class="text-body-1 mt-3">{{ profile.description }}</div>
        </v-col>
      </v-row>

      <v-row class="hidden-md-and-up mt-3">
        <v-divider />
        <v-col cols="3">
          <div>
            <span class="font-weight-bold">{{ profile.followers }} </span
            >followers
          </div>
        </v-col>
        <v-col cols="3">
          <div>
            <span class="font-weight-bold">{{ profile.following }} </span
            >following
          </div>
        </v-col>
        <v-divider />
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col v-if="profile.tik_tok_url" cols="12" sm="6" md="4" lg="3">
          <v-btn
            outlined
            class="button-width"
            @click="openUrl(profile.tik_tok_url)"
          >
            <v-icon>mdi-tiktok</v-icon>
            <v-icon>fa-brands fa-tiktok</v-icon>
          </v-btn>
        </v-col>

        <v-col v-if="profile.instagram_url" cols="12" sm="6" md="4" lg="3">
          <v-btn
            outlined
            class="button-width"
            @click="openUrl(profile.instagram_url)"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-col>

        <v-col v-if="profile.web_url" cols="12" sm="6" md="4" lg="3">
          <v-btn
            outlined
            class="button-width"
            @click="openUrl(profile.web_url)"
          >
            <v-icon left>mdi-earth</v-icon>
            {{ profile.web_name || "Web" }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="justify-end mr-10" @click="addEvent">
        <v-btn tile color="success">
          <v-icon left>mdi-plus</v-icon>
          Add Event
        </v-btn>
      </v-row>

      <v-row>
        <template v-if="loading">
          <v-col v-for="n in 6" :key="n" :cols="12" :sm="12" :md="4" :lg="4">
            <v-skeleton-loader type="card" height="250"></v-skeleton-loader>
          </v-col>
        </template>
        <template v-else>
          <v-col
            v-for="event in events"
            :key="event.id"
            :cols="12"
            :sm="12"
            :md="4"
            :lg="4"
          >
            <v-card>
              <div class="card-img-container">
                <img
                  class="image"
                  :src="'https://pinn.app/admin/uploads/' + event.picture"
                  @error="setAltImg"
                />
                <div class="date-badge">{{ daysLeft(event.start_date) }}</div>
              </div>

              <v-card-title>{{ event.name }}</v-card-title>
              <v-card-subtitle>{{ event.location }}</v-card-subtitle>
              <v-card-text>{{ event.start_date }}</v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-container class="mt-12" v-else>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="text-center">
            <v-icon x-large class="error--text"
              >mdi-emoticon-sad-outline</v-icon
            >
            <div class="text-h5 mt-2 error--text">
              Sorry, no records were found for this user.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

    
<script>
import { getUser, getEvents } from "./../services/API";

export default {
  name: "UserEvent",
  data() {
    return {
      record: true,
      user_id: "",
      loading: true,
      profile: {
        photo: "",
        name: "",
        description: "",
        followers: "",
        following: "",
        instagram_url: "",
        web_url: "",
        web_name: "",
        tik_tok_url: "",
      },
      events: [],
    };
  },
  mounted() {
    //   const user_name = this.$route.query.name;
    const user_name = this.$route.params.name;
    getUser(user_name)
      .then((response) => {
        this.profile.photo = response.data[0].user_profile_photo;
        this.profile.name = response.data[0].user_name;
        this.profile.followers = response.data[0].follower_count;
        this.profile.following = response.data[0].following_count;
        this.profile.description = response.data[0].user_about_me;
        this.user_id = response.data[0].user_id;
        this.profile.web_url = response.data[0].web_url;
        this.profile.tik_tok_url = response.data[0].tiktok_url;
        this.profile.web_name = response.data[0].web_name;
        this.profile.instagram_url = response.data[0].instagram_url;
        setTimeout(() => {
          this.getEvents(this.user_id);
        }, 500);
      })
      .catch((error) => {
        this.record = false;
        console.error(error);
      });
  },
  computed: {
    eventImageUrl() {
      return (event) => {
        return "https://pinn.app/admin/uploads/" + event.picture;
      };
    },
  },
  methods: {
    daysLeft(start_date) {
      const now = new Date();
      now.setUTCHours(0, 0, 0, 0); // set to midnight UTC
      const eventDate = new Date(start_date);
      eventDate.setUTCHours(0, 0, 0, 0); // set to midnight UTC
      const diff = eventDate - now;
      if (diff < 0) {
        return "Event has ended";
      }
      const diffInHours = Math.floor(diff / (1000 * 60 * 60));
      if (diffInHours < 24) {
        return `${diffInHours} hours left`;
      }
      const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      return `${diffInDays} days left`;
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    setAltImg(event) {
      event.target.src = "https://pinn.app/admin/uploads/apartment1.jpg";
    },
    setDefaultImage: (event) => {
      event.target.src = "https://pinn.app/admin/uploads/apartment1.jpg";
    },
    getEvents(user_id) {
      getEvents(user_id)
        .then((response) => {
          const result = response.data;
          this.events = result.map((event) => ({
            id: event.id,
            name: event.title,
            date: event.added_date.split(" ")[0],
            location: event.item_location_city.name,
            picture: event.default_photo.img_path,
            start_date: event.start_date,
          }));
          this.events.sort((a, b) => {
            const daysLeftA = this.calculateDaysLeft(a.start_date);
            const daysLeftB = this.calculateDaysLeft(b.start_date);

            if (daysLeftA < 0 && daysLeftB < 0) {
              return daysLeftB - daysLeftA;
            } else if (daysLeftA < 0) {
              return 1;
            } else if (daysLeftB < 0) {
              return -1;
            }

            return daysLeftA - daysLeftB;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },
    calculateDaysLeft(start_date) {
      const now = new Date();
      now.setUTCHours(0, 0, 0, 0);
      const eventDate = new Date(start_date);
      eventDate.setUTCHours(0, 0, 0, 0);
      const diff = eventDate - now;
      return Math.floor(diff / (1000 * 60 * 60 * 24));
    },

    addEvent() {
      this.$router.push("add-event");
    },
  },
};
</script>
  <style>
@media (max-width: 600px) {
  .follow-btn {
    margin-top: 10px;
  }
  .text-h4 {
    font-size: 22px !important;
  }
  .text-body-1 {
    font-size: 14px !important;
  }
  .post-img {
    width: 100%;
    height: auto;
  }
}
.card-img-container {
  position: relative;
  padding-bottom: 75%; 
}

.card-img-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.date-badge {
  position: absolute;
  top: 12px;
  right: 8px;
  background-color: white;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}

.button-width {
  width: 100%;
}
</style>
    