import Vue from "vue";
import VueRouter from "vue-router";
import UserEvents from "../views/UserEvent.vue";
import AddEvent from "../views/AddEvent.vue";
import Home from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,

  },
  {
    path: "/add-event",
    name: "add-events",
    component: AddEvent,
  },
  {
    path: "/:name",
    name: "user-events",
    component: UserEvents,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
