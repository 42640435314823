<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height class="survey-container">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <h1 class="text-white text-center event-title" id="event">
              Add Event
            </h1>
            <v-card class="survey-card rounded-card">
              <v-card-text>
                <v-form @submit.prevent="submitForm" id="survey-form">
                  <v-text-field
                    v-model="form.title"
                    label="Title"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>
                  <v-select
                    v-model="form.item_currency_id"
                    :items="currencies"
                    item-text="currency_symbol"
                    :item-value="'id'"
                    label="Select Currency"
                    required
                    outlined
                    dense
                    rounded
                  ></v-select>

                  <v-text-field
                    v-model="form.price"
                    label="Price"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>

                  <v-select
                    v-model="form.item_location_township_id"
                    :items="townships"
                    label="Select Township"
                    item-text="township_name"
                    :item-value="'id'"
                    required
                    outlined
                    dense
                    rounded
                  ></v-select>
                  <v-select
                    v-model="form.item_location_city_id"
                    :items="cities"
                    label="Select City"
                    item-text="city_name"
                    :item-value="'id'"
                    required
                    outlined
                    dense
                    rounded
                  ></v-select>

                  <v-select
                    v-model="form.user_name"
                    :items="usernames"
                    label="Select Username"
                    item-text="username"
                    required
                    outlined
                    dense
                    rounded
                  ></v-select>

                  <v-select
                    v-model="form.property_by_id"
                    :items="properties"
                    label="Select Property"
                    item-text="name"
                    :item-value="'id'"
                    required
                    outlined
                    dense
                    rounded
                  ></v-select>

                  <v-text-field
                    v-model="form.price_unit"
                    label="Price Unit"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>
                  <v-text-field
                    v-model="form.price_note"
                    label="Price Note"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>
                  <v-file-input
                    v-model="form.image"
                    label="Image"
                    accept="image/*"
                    outlined
                    dense
                    rounded
                  ></v-file-input>
                  <v-text-field
                    v-model="form.start_date"
                    label="Start Date"
                    type="date"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>
                  <v-text-field
                    v-model="form.stop_date"
                    label="Stop Date"
                    type="date"
                    required
                    outlined
                    dense
                    rounded
                  ></v-text-field>
                  <v-text-field
                    type="text"
                    placeholder="Enter your address"
                    v-model="form.address"
                    ref="autocomplete"
                    @input="fetchAddressSuggestions"
                    rounded
                    outlined
                    dense
                  ></v-text-field>
                  <div id="map" style="height: 400px"></div>
                  <div class="mt-6"></div>
                  <v-textarea
                    v-model="form.description"
                    label="Description"
                    outlined
                    dense
                    rounded
                  ></v-textarea>

                  <v-btn color="primary" type="submit">Submit</v-btn>
                  <v-snackbar
                    v-model="success"
                    :color="success ? 'success' : 'error'"
                    top
                    right
                    :timeout="3000"
                  >
                    {{ success ? "Event posted successfully" : error }}
                  </v-snackbar>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>
  
  <script>
import {
  getCurrency,
  getTownship,
  getProperty,
  allUser,
  postEvents,
  getCities,
} from "./../services/API";
import { VSnackbar } from "vuetify/lib";

export default {
  components: {
    VSnackbar,
  },
  data() {
    return {
      form: {
        title: "",
        item_currency_id: null,
        price: "",
        item_location_township_id: null,
        item_location_city_id: null,
        user_name: null,
        property_by_id: null,
        price_unit: "",
        price_note: "",
        start_date: "",
        stop_date: "",
        address: "",
        description: "",
        added_user_id: "",
        image: null,
        lat: null,
        lng: null,
      },
      map: null,
      marker: null,
      currencies: [],
      townships: [],
      cities: [],
      usernames: [],
      properties: [],
      suggestions: [],
      success: false,
      error: null,
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCirhhA83U0U89QIIw-cD7YobwqbcyOVoE&libraries=places`;
    script.onload = () => {
      this.initMap();
      this.initAutocomplete();
    };
    document.head.appendChild(script);
    this.fetchCurrencies();
    this.fetchUsernames();
    this.fetchTownships();
    this.fetchProperties();
    this.fetchCities();
  },
  methods: {
    submitForm() {
      const selectedUser = this.usernames.find(
        (user) => user.username === this.form.user_name
      );
      if (selectedUser) {
        this.form.added_user_id = selectedUser.id;
      }
      const formData = new FormData();
      formData.append("image", this.form.image);
      formData.append("title", this.form.title);
      formData.append("item_currency_id", this.form.item_currency_id);
      formData.append(
        "item_location_township_id",
        this.form.item_location_township_id
      );

      formData.append("user_name", this.form.user_name);
      formData.append("property_by_id", this.form.property_by_id);
      formData.append("price_unit", this.form.price_unit);
      formData.append("price_note", this.form.price_note);
      formData.append("start_date", this.form.start_date);
      formData.append("stop_date", this.form.stop_date);
      formData.append("added_user_id", this.form.added_user_id);
      formData.append("description", this.form.description);
      formData.append("address", this.form.address);
      formData.append("price", this.form.price);
      formData.append("item_location_city_id", this.form.item_location_city_id);
      formData.append("lat", this.form.lat);
      formData.append("lng", this.form.lng);
      console.log(formData);

      postEvents(formData)
        .then(() => {
          this.success = true;
          this.form = "";
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({ data: { message: "Event posted successfully" } });
            }, 2000);
          });
        })
        .catch((err) => {
          console.log(err);
          this.error = "Something went wrong.";
          this.success = false;
          return Promise.reject(err);
        });
    },

    fetchCurrencies() {
      getCurrency()
        .then((response) => {
          this.currencies = response.data.map((currency) => {
            return {
              currency_symbol: currency.currency_symbol,
              id: currency.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchUsernames() {
      allUser()
        .then((response) => {
          this.usernames = response.data.map((user) => {
            return {
              username: user.user_name,
              id: user.user_id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchTownships() {
      getTownship()
        .then((response) => {
          this.townships = response.data.map((township) => {
            return {
              township_name: township.township_name,
              id: township.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCities() {
      getCities()
        .then((response) => {
          this.cities = response.data.map((city) => {
            return {
              city_name: city.name,
              id: city.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProperties() {
      getProperty()
        .then((response) => {
          this.properties = response.data.map((property) => {
            return {
              name: property.name,
              id: property.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 0, lng: 0 },
        zoom: 4,
      });

      this.marker = new window.google.maps.Marker({
        map: this.map,
        draggable: true,
      });

      this.map.addListener("click", (event) => {
        this.marker.setPosition(event.latLng);
        this.form.lat = event.latLng.lat();
        this.form.lng = event.latLng.lng();
        // this.geocodeLocation(event.latLng);
      });

      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.form.lat = position.lat();
        this.form.lng = position.lng();
        // this.geocodeLocation(position);
      });
    },
    initAutocomplete() {
      const input = this.$refs.autocomplete.$el.querySelector("input");
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(10);
        this.marker.setPosition(place.geometry.location);
        this.form.lat = place.geometry.location.lat();
        this.form.lng = place.geometry.location.lng();
        // this.geocodeLocation(place.geometry.location);
      });
      this.geocoder = new window.google.maps.Geocoder();
    },
    // geocodeLocation(location) {
    //   this.geocoder.geocode({ location: location }, (results, status) => {
    //     if (status === "OK" && results[0]) {
    //       this.form.address = results[0].formatted_address;
    //     }
    //   });
    // },
 
  
  
  },
};
</script>
  
  <style>
.survey-container {
  background-image: linear-gradient(
      115deg,
      rgba(58, 58, 158, 0.8),
      rgba(136, 136, 206, 0.7)
    ),
    url(https://cdn.freecodecamp.org/testable-projects-fcc/images/survey-form-background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.survey-card {
  border-radius: 8px;
}

#event {
  color: white !important;
}
</style>
  