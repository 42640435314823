import axios from "axios";
const BASE_URL = "https://pinn.app/admin/index.php/rest/";
const ACCEPT_HEADER = "application/json";
export function getUser(user_name) {
  const url = `${BASE_URL}Users/get/api_key/teampinnisthebestofthebest/login_user_id/?user_name=${user_name}`;

  // const url = `${BASE_URL}Users/get/?user_name=${user_name}`
  const headers = {
    Accept: ACCEPT_HEADER,
  };
  return axios.get(url, { headers });
}
export function getEvents(user_id) {
  const url = `${BASE_URL}Items/get/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8&added_user_id=${user_id}`;

  const headers = {
    Accept: ACCEPT_HEADER,
  };
  return axios.get(url, { headers });
}

export function postEvents(formData) {
    const url = `${BASE_URL}Items/add/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8`;
  
    const headers = {
      Accept: ACCEPT_HEADER,
      'Content-Type': 'multipart/form-data' 
    };
  
    return axios.post(url, formData, { headers });
  }



export function getCurrency() {
  const url = `${BASE_URL}Currencies/get/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8&added_user_id`;

  const headers = {
    Accept: ACCEPT_HEADER,
  };
  return axios.get(url, { headers });
}

export function getTownship(){
    const url = `${BASE_URL}Item_location_townships/get/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8&added_user_id`;

    const headers = {
      Accept: ACCEPT_HEADER,
    };
    return axios.get(url, { headers });

}

export function getCities(){
  const url = `${BASE_URL}Itemlocations/get/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8&added_user_id`;

  const headers = {
    Accept: ACCEPT_HEADER,
  };
  return axios.get(url, { headers });

}

export function getProperty(){
    const url = `${BASE_URL}Propertybys/get/api_key/teampinnisthebestofthebest/login_user_id/?user_id=usr4467b402e229579504a0fedbb579e5c8&added_user_id`;

    const headers = {
      Accept: ACCEPT_HEADER,
    };
    return axios.get(url, { headers });

}

export function allUser(){
    const url = `${BASE_URL}Users/get/api_key/teampinnisthebestofthebest/login_user_id`;

    const headers = {
      Accept: ACCEPT_HEADER,
    };
    return axios.get(url, { headers });

}