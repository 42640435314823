<template>
  <div>

<nav class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
       

        <!-- Image Logo -->
        <a class="navbar-brand logo-image" href="/"><img src="../assets/images/web-logo.png" alt="alternative"></a> 
        
        
    </nav>
            


    <header id="header" class="header">
      <div class="header-content" style="padding-bottom: 0px;">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="text-container" style="padding: 0px;">
                <h1>
                    Live a little!<br />WITH
                    <!-- <span id="js-rotating">New Friends, Events, Travels</span> -->
                    <span id="js-rotating">{{ currentText }}</span>
                  </h1>
                <p class="p-large">
                  Find people in your city, or as you travel, to make meaningful connections!
                </p>
                <a
                  class="btn-solid-lg page-scroll"
                  href="https://apps.apple.com/us/app/pinn-social/id1670478989"
                  ><i class="fab fa-apple"></i>APP STORE</a
                >
              <!--  <a
                  class="btn-solid-lg page-scroll"
                  href="https://play.google.com/store/apps/details?id=com.app.Pinn&hl=en_US"
                  ><i class="fab fa-google-play"></i>PLAY STORE</a
                > -->
              </div>
            </div>
            <div class="col-lg-6">
              <div class="image-container">
                    <v-img
                      class="img-fluid"
                      src="../assets/images/web-iphone.png"
                      alt="alternative"
                    ></v-img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- end of header -->
    <!-- end of header -->

    <!-- Details 1 -->
    <div id="details" class="basic-2">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-img
                class="img-fluid"
                src="../assets/images/details-1-iphone.png"
                alt="alternative"
              ></v-img>
            </v-col>
            <!-- end of col -->
            <v-col cols="12" lg="6">
              <div class="text-container">
                <h3>For Business</h3>
                <p>Use Pinn as a tool to get more foot traffic or to organize your events. Drive more business all for free.</p>
                <a
                  class="btn-solid-reg popup-with-move-anim"
                  href="#details-lightbox-1"
                >Learn More</a>
              </div>
              <!-- end of text-container -->
            </v-col>
            <!-- end of col -->
          </v-row>
          <!-- end of row -->
        </v-container>
        <!-- end of container -->
      </div>
      <!-- end of basic-2 -->
    
      <!-- Details 2 -->
      <div class="basic-3">
        <div class="second">
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <div class="text-container">
                  <h3>For life experiences</h3>
                  <p>
                    Don't go at it alone! Find people to join you to places or events, while in your city or as you travel. 
                  </p><p></p><p>Meeting new people and making meaningful connections has never been easier!</p>
                  <!-- <a class="btn-solid-reg popup-with-move-anim" href="#details-lightbox-2">LIGHTBOX</a> -->
                </div>
                <!-- end of text-container -->
              </v-col>
              <!-- end of col -->
              <v-col cols="12" lg="6">
                <v-img
                  class="img-fluid"
                  src="../assets/images/details-2-iphone.png"
                  alt="alternative"
                ></v-img>
              </v-col>
              <!-- end of col -->
            </v-row>
            <!-- end of row -->
          </v-container>
          <!-- end of container -->
        </div>
        <!-- end of second -->
      </div>
    <!-- end of basic-3 -->
    <!-- end of details 2 -->

    <!-- Details Lightboxes -->
    <!-- Lightbox -->
    <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <!-- <div
      id="details-lightbox-2"
      class="lightbox-basic zoom-anim-dialog mfp-hide"
    >
      <div class="row">
        <button title="Close (Esc)" type="button" class="mfp-close x-button">
          ×
        </button>
        <div class="col-lg-6">
          <img
            class="img-fluid"
            src="../assets/images/details-lightbox-2.png"
            alt="alternative"
          />
        </div>
        <div class="col-lg-6">
          <h3>Calendar Input</h3>
          <hr />
          <p>
            The calendar input function enables the user to setup training,
            meditation and relaxation sessions with ease.
          </p>
          <h4>User Feedback</h4>
          <p>
            This is a great app which can help you save time and make your live
            easier. And it will help improve your productivity levels.
          </p>
          <p>You should definitely give it a try when you need a good app.</p>
          <table>
            <tr>
              <td class="icon-cell"><i class="fas fa-desktop"></i></td>
              <td>Responsive layout</td>
            </tr>
            <tr>
              <td class="icon-cell"><i class="fas fa-bullhorn"></i></td>
              <td>Distinctive CTAs</td>
            </tr>
            <tr>
              <td class="icon-cell"><i class="fas fa-image"></i></td>
              <td>Image gallery slider</td>
            </tr>
            <tr>
              <td class="icon-cell"><i class="fas fa-envelope"></i></td>
              <td>Contact forms</td>
            </tr>
            <tr>
              <td class="icon-cell">
                <i class="fab fa-font-awesome-flag"></i>
              </td>
              <td>FontAwesome icons</td>
            </tr>
            <tr>
              <td class="icon-cell"><i class="fas fa-code"></i></td>
              <td>Well-structured code</td>
            </tr>
          </table>
          <a class="btn-solid-reg" href="#your-link">DETAILS</a>
          <a class="btn-outline-reg mfp-close as-button" href="#details"
            >BACK</a
          >
        </div>
      </div>
      end of row -->
    <!-- </div> -->
    <!-- end of lightbox-basic -->
    <!-- end of lightbox -->
    <!-- end of details lightboxes -->

    <!-- Download -->
    <div class="basic-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-container">
              <h2>Download <span class="blue">Pinn</span></h2>
              <p class="p-large">
                We only live once! Maybe? Anyways, we try to make the best out of it with Pinn.
              </p>
              <a
                class="btn-solid-lg"
                href="https://apps.apple.com/us/app/pinn-social/id1670478989"
                ><i class="fab fa-apple"></i>APP STORE</a
              >
             <!-- <a
                class="btn-solid-lg"
                href="https://play.google.com/store/apps/details?id=com.app.Pinn&hl=en_US"
                ><i class="fab fa-google-play"></i>PLAY STORE</a
              > -->
            </div>
            <!-- end of text-container -->
          </div>
          <!-- 
                <div class="col-lg-6 col-xl-7">
                    <div class="image-container">
                        <img class="img-fluid" src="images/download.png" alt="alternative">
                    </div>  -->
          <!-- end of img-container -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->

    <!-- </div> end of basic-4 -->
    <!-- end of download -->

    <div class="footer">
      <div class="container">
        <div class="row"></div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- end of footer -->
    <!-- end of footer -->

    <!-- Copyright -->
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p class="p-small">
              Copyright © 2024 <a href="https://Pinn.app">Pinn.app</a> - All
              rights reserved. <a href="https://info.pinn.app/privacypolicy.html">Privacy Policy</a> - <a href="https://info.pinn.app/terms.html">Terms of Use</a>
            </p>
          </div>
          <!-- end of col -->
        </div>
        <!-- enf of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- end of copyright -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      texts: ['New Friends', 'Events', 'Travels'],
      currentIndex: 0,
    };
  },
  computed: {
    currentText() {
      return this.texts[this.currentIndex];
    },
  },
  mounted() {
    this.updateText(); // Call the function initially
    setInterval(this.updateText, 1000); // Call the function every 3 seconds
  },
  methods: {
    updateText() {
      this.currentIndex = (this.currentIndex + 1) % this.texts.length;
    },
  },
};
</script>
<style scoped>
@import '../assets/style.css'
</style>